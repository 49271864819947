import React, { useState, useEffect, useContext } from "react";
import PropTypes from "prop-types";
import { isMobileDevice } from "responsive-react";
import { AppSettingsContext } from "../../App";
import { AuthContext } from "../../auth/AuthProvider";

// Components
import NavbarMobile from "./navbar-mobile";
import NavbarOriginal from "./navbar-original";



const Navbar = ({
  transparent,
  light,
  noToggle,
  expandedVisible,
}) => {
  const [isMobile, setIsMobile] = useState(isMobileDevice());
  const { site_phase: sitePhase, global_newsletter_sent: newsletterSent, download_link: downloadLink } = useContext(AppSettingsContext);
  const me = useContext(AuthContext);
  const likedStoriesLength = me?.liked_stories?.length ?? 0;

  useEffect(() => {
    const handleResize = () => {
      setIsMobile(isMobileDevice());
    }
    window.addEventListener('resize', handleResize);
    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);

  return isMobile ? (
    <NavbarMobile
      sitePhase={sitePhase}
      newsletterSent={newsletterSent}
      downloadLink={downloadLink}
      transparent={transparent}
      noToggle={noToggle}
      minimized
      expandedVisible={expandedVisible}
      likedStoriesLength={likedStoriesLength}
    />
  ) : (
    <NavbarOriginal
      sitePhase={sitePhase}
      newsletterSent={newsletterSent}
      downloadLink={downloadLink}
      transparent={transparent}
      light={light}
      noToggle={noToggle}
      expandedVisible={expandedVisible}
      likedStoriesLength={likedStoriesLength}
    />
  )
};
Navbar.defaultProps = {
  transparent: false,
  noToggle: false,
  expandedVisible: false,
};

Navbar.propTypes = {
  transparent: PropTypes.bool,
  noToggle: PropTypes.bool,
  expandedVisible: PropTypes.bool,
};

export default React.memo(Navbar);
