import React from "react";
import ReactDOM from "react-dom/client";
import { ApolloClient, InMemoryCache, ApolloProvider } from "@apollo/client";
import { createUploadLink } from "apollo-upload-client";
import App from "./App";
import reportWebVitals from "./reportWebVitals";
import { BrowserRouter } from "react-router-dom";
import TagManager from "react-gtm-module";

//Authentification
import AuthProvider from "./auth/AuthProvider";
import {
  PublicClientApplication,
  EventType,
  BrowserUtils,
} from "@azure/msal-browser";
import { msalConfig } from "./auth/authConfig";

// Styles
import "./assets/fonts/aksana.css";
import "./assets/fonts/bureau.css";
import "./assets/styles/base.scss";

//This variable should be added only to prod
if (process.env.REACT_APP_GA_TRACKING_ID) {
  // if (process.env.REACT_APP_USE_ENV==="PROD")
  TagManager.initialize({
    gtmId: process.env.REACT_APP_GA_TRACKING_ID,
  });
}

//apollo client
export const apolloClient = new ApolloClient({
  uri: `${process.env.REACT_APP_API_BASE_URL}/graphql`,
  link: createUploadLink({
    uri: `${process.env.REACT_APP_API_BASE_URL}/graphql`,
  }),
  cache: new InMemoryCache(),
});

/**
 * MSAL should be instantiated outside of the component tree to prevent it from being re-instantiated on re-renders.
 * For more, visit: https://github.com/AzureAD/microsoft-authentication-library-for-js/blob/dev/lib/msal-react/docs/getting-started.md
 */
export const msalInstance = new PublicClientApplication(msalConfig);

try {
  const _init = await msalInstance.initialize();
  console.log(_init);

  // //logout
  // msalInstance.logoutRedirect({
  //   account: msalInstance.getActiveAccount(),
  //   onRedirectNavigate: () => !BrowserUtils.isInIframe(),
  // });

  // // Default to using the first account if no account is active on page load
  const _allAccounts = msalInstance?.getAllAccounts();
  if (_allAccounts?.length > 0 && !msalInstance?.getActiveAccount()) {
    // Account selection logic is app dependent. Adjust as needed for different use cases.
    msalInstance.setActiveAccount(_allAccounts[0]);
  }

  // Listen for sign-in event and set active account
  msalInstance.addEventCallback((event) => {
    if (event.eventType === EventType.LOGIN_SUCCESS && event.payload.account) {
      const account = event.payload.account;
      msalInstance.setActiveAccount(account);
    }
  });

  // handle auth redired/do all initial setup for msal
  msalInstance
    .handleRedirectPromise()
    .then((authResult) => {
      // Check if user signed in
      const account = msalInstance.getActiveAccount();
      if (!account) {
        // redirect anonymous user to login page
        msalInstance.loginRedirect();
      }
    })
    .catch((err) => {
      // TODO: Handle errors
      console.error(err);
    });
} catch (error) {
  console.error(error);
}

const root = ReactDOM.createRoot(document.getElementById("root"));
root.render(
  // <React.StrictMode>  //TODO: with strict mode the "react-move" and "simpleParallax" libraries will stop working on debug mode! https://github.com/sghall/react-move/issues/88
  <AuthProvider msalInstance={msalInstance}>
    <ApolloProvider client={apolloClient}>
      <BrowserRouter>
        <App />
      </BrowserRouter>
    </ApolloProvider>
  </AuthProvider>
  // </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
