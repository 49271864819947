import React from "react";

export const ERROR = "ERROR";
export const RESET_STATE = "RESET_STATE";
export const NOT_AVAILABLE = "-";
export const DATE_FORMAT = "dd-LLL-yyyy";
export const FILENAME_TIMESTAMP_FORMAT = "yyyy-MM-dd_HH-mm-ss-SSS";

export const SHARE_STORY_SUBJECT = "Share your story";
export const SHARE_STORY_BODY = `
Your name:%0D%0A
Your email:%0D%0A
Country (where you are based):%0D%0A
Title of the story:%0D%0A
Category of the story (see options below; choose 3 maximum): %0D%0A
Country (where the story happened):%0D%0A
Description of the story:%0D%0A
Name of your Local HR:%0D%0A
Photos and/or videos (as attachment)%0D%0A
%0D%0A
Categories:%0D%0A
Back to the Bar%0D%0A
BEST10 Disrupt Future Moments that Matter%0D%0A
BEST10 Own the On-Trade%0D%0A
BEST10 Place Bold Bets%0D%0A
BEST10 Reignite Marketing%0D%0A
Consumer at the Heart%0D%0A
Founder’s Day%0D%0A
Global Reflections%0D%0A
Good Spirited%0D%0A
Life in the Q%0D%0A
New Better%0D%0A
Open #365Digital%0D%0A
Primos & Culture%0D%0A
`;

export const ABOUT = (
  <>
    Our 158-year history is filled with numerous stories of triumphs, trials and
    turning points. In true Bacardi spirit, we have always stood strong and
    rallied together to come out stronger and better than before. That’s why
    we’ve launched Moments that Matter, a site where you’ll find stories created
    by Primos from around the world that chronicle our strength, resilience,
    creativity and agility.
    <br />
    <br />
    The platform will enable us to connect with each other as we share and
    explore the different Moments that Matter in our lives across a wide range
    of categories, including Primos & Culture, The New Better, Good Spirited,
    and more, that have impacted how we think, work and connect. We invite you
    to explore your fellow Primos’ experiences and build upon our legacy by
    sharing your stories today!
  </>
);

export const BEST_STORIES_TITLE = "OUR BEST STORIES!";

export const BEST_STORIES_DESCRIPTION = (
  <>
    Primos around the world have shared stories of hundreds of inspiring
    teammates who stepped up like never before.
    <br />
    Vote for your favorite Ultimate Founder by visiting each category tab. Hit
    &ldquo;Like&rdquo; for one Primo story per category.
    <br />
    <strong>The winners will be announced on Founder's Day!</strong>
  </>
);

export const STEP_TWO_NOMINATE_DESCRIPTION = (
  <>Tell us who deserves the biggest recognition of the year!</>
);

export const SUCCESS_DESCRIPTION_OLD = (
  <>
    Well done! Your story will appear as soon as your Local HR Business Partner
    publishes it.
    <br />
    Meanwhile, check out other inspiring stories on the global map!
  </>
);

export const SUCCESS_DESCRIPTION = (
  <>
    Well done! The Culture Team will review your nomination and reach out with
    any questions. In the meantime, check out stories from past winners on the
    main page.
  </>
);

export const SUCCESS_NOMINATE_DESCRIPTION =
  "Thank you for your Ultimate Founder nomination. Stay tuned for the shortlist!";

export const MAKE_MOMENTS_MATTER = "MAKE MOMENTS MATTER";
export const OUR_WINNERS = "OUR WINNERS";
export const PAST_WINNERS = "PAST WINNERS";

export const ULTIMATE_FOUNDERS_SHORTLIST = "Ultimate Founders Finalists";
export const ULTIMATE_FOUNDERS = "Ultimate Founders Competition";
export const ULTIMATE_FOUNDER_WINNERS = "Our Ultimate Founders";
export const GLOBAL_ACTIVATION = "Global activations";
export const ULTIMATE_FOUNDER_WINNERS_TITLE = <>Ultimate Founders Winners</>;
export const ULTIMATE_FOUNDER_WINNERS_DESCRIPTION = (
  <>
    Primos around the world shared hundreds of stories of inspiring teammates
    who stepped up like never before.
    <br />
    We are delighted to name the Primos who have been voted our Ultimate
    Founders.
    <br />
    Check out their stories!
  </>
);

export const PAST_WINNERS_DESCRIPTION = (
  <>
    Be inspired by our past Ultimate Founders Winners.
    <br />
    Discover how they Make Moments Matter, and embody Fearless, Family and
    Founders.
  </>
);

export const PURPOSE_DESCRIPTION = (
  <>
    Check out some of our favorite resources on the role Purpose can play in
    driving our performance.
    <br />
    These are external links, but they reflect our Bacardi spirit.
  </>
);

export const VIDEO_SECTION_TEXT = <>Watch primos make moments matter</>;

export const VOTE = "VOTE";
export const VIEW = "VIEW";
export const NOMINATE = "NOMINATE";
export const PURPOSE = "PURPOSE";
export const DOWNLOAD = "DOWNLOAD";
export const ALL_STORIES = "ALL STORIES";

export const LIKED_STORIES = "LIKED STORIES";
export const LIKED_STORIES_NAV = "LIKED";

export const COMMITTEE_STORIES = "COMMITTEE STORIES";
export const COMMITTEE_STORIES_NAV = "COMMITTEE";

export const ASSETS = "ASSETS";

export const PUBLISHED_STORIES = "PUBLISHED STORIES";
export const PUBLISHED_STORIES_NAV = "PUBLISHED";

export const DROP_YOUR_DOCUMENTS_HERE =
  "Drop your document here or browse your files";
