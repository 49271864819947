import React, { useContext } from "react";
import PropTypes from "prop-types";
import { DateTime } from "luxon";
import { motion } from "framer-motion";
import ProgressiveImage from "react-progressive-graceful-image";

import { Icons } from "../../../components/icon";
import { isFeatureAvailable } from "../../../helpers/common";
import { DATE_FORMAT } from "../../../constants/common";
import { StoryContext } from "../explore-story-renderer";
import { AnimatedContent } from "../../../components/animated-content";

const ExploreStoryHeaderContent = ({
  coverImage,
  coverImageThumb,
  location,
  title,
  author,
  date,
  rank,
  nominatedAuthor,
}) => {
  const { is_winner } = useContext(StoryContext);

  return (
    <>
      <ProgressiveImage src={coverImage} placeholder={coverImageThumb}>
        {(src) => (
          <motion.div
            initial={{ opacity: 0 }}
            animate={{ opacity: 1 }}
            transition={{ duration: 0.7, delay: 0 }}
            className="bc-explore-story__header-background-image"
            style={{ backgroundImage: `url(${src})` }}
          />
        )}
      </ProgressiveImage>
      <motion.div
        className="bc-explore-story__header-background-overlay"
        initial={{ opacity: 0 }}
        animate={{ opacity: 0.7 }}
        transition={{ duration: 3, delay: 0.35, ease: "backOut" }}
      />
      <div className="bc-explore-story__title-block" data-testid="title-block">
        {location && (
          <AnimatedContent
            translate={0}
            delay={0.7}
            custom={1}
            className="bc-explore-story__title-block-row"
          >
            <span className="icon">{Icons.MARKER}</span>
            <span className="label uppercase">{location}</span>
          </AnimatedContent>
        )}
        <div className="bc-explore-story__title-block-row">
          <AnimatedContent className="title uppercase">{title}</AnimatedContent>
        </div>
        <div className="bc-explore-story__title-block-row">
          <AnimatedContent translate={0} delay={0.7} custom={1}>
            <div className="bc-explore-story__title-block-row_wrapper">
              <span className="avatar">{Icons.BAT_OUTLINED}</span>
              <span className="label simple">{author}</span>
              <span className="calendar" style={{ paddingLeft: "26px" }}>
                {Icons.CALENDAR}
              </span>
              <span className="label simple">
                {DateTime.fromISO(date).toFormat(DATE_FORMAT)}
              </span>
            </div>
          </AnimatedContent>
        </div>
        {nominatedAuthor && (
          <div
            className="bc-explore-story__title-block-row column mar-19"
            data-testid="author"
          >
            <div className="inline center">
              <span className="cheers">{Icons.CELEBRATE}</span>
              <span className="label important">Cheers!</span>
            </div>
            <div className="label nominate">
              {nominatedAuthor}{" "}
              {is_winner
                ? "is an Ultimate Founders Winner"
                : "is an Ultimate Founders Finalist"}
            </div>
          </div>
        )}
      </div>
      {isFeatureAvailable(1) && rank === 1 && (
        <div className="bc-explore-story__header-rank-badge">
          <span className="badge-icon">{Icons.CUP_FILLED}</span>
          <span className="badge-label">1rst position</span>
        </div>
      )}
    </>
  );
};

ExploreStoryHeaderContent.defaultProps = {
  rank: 0,
  nominatedAuthor: null,
};

ExploreStoryHeaderContent.propTypes = {
  coverImage: PropTypes.string,
  coverImageThumb: PropTypes.string,
  location: PropTypes.string.isRequired,
  title: PropTypes.string.isRequired,
  author: PropTypes.string.isRequired,
  date: PropTypes.string.isRequired,
  rank: PropTypes.number,
  nominatedAuthor: PropTypes.string,
};

export default ExploreStoryHeaderContent;
