import React from 'react';
import { Icons } from '../icon';

import { askForSupport } from '../../helpers/common';

import './support.scss';

const Support = ({theme}) => (
  <div className={`bc-support-message ${theme}`}>
    <span className='bc-support-icon'>{Icons.BAT_OUTLINED}</span>
    <span>
      <strong>Need support?</strong>&nbsp;
    </span>
    <span>Send an email to</span>
    <span
      className='link'
      onClick={() => askForSupport()}>
      {process.env.REACT_APP_SHARE_STORY_EMAIL}
    </span>
  </div>
);

export default Support;
