import React, { useLayoutEffect, useState, useEffect, useContext } from "react";
import { Col, Grid, Row } from "react-flexbox-grid";
import clsx from "clsx";
import { isMobileDevice } from "responsive-react";
// Components
import Navbar from "../../components/navbar";
import Support from "../../components/support";
import Copyright from "../../components/copyright/copyright";
import Footer from "../../components/footer";
import PastWinnersGrid from "./PastWinnersGrid";

// Styles
import "../best-stories/best-stories.scss";

import { PAST_WINNERS, PAST_WINNERS_DESCRIPTION } from "../../constants/common";
import { useTransparent } from "../../helpers/hooks";
import { AnimatedContent } from "../../components/animated-content";
import { AppSettingsContext } from "../../App";


const PastWinners = () => {
  const { transparent } = useTransparent();
  const { nomination_year, download_link, site_phase } = useContext(AppSettingsContext);
  const [pastWinnersVisiblePeriods, setPastWinnersVisiblePeriods] = useState(1);
  // const data = useSelector(getPastWinners);

  useLayoutEffect(() => {
    window.scrollTo(0, 0);
  }, []);


  return (
    <div id="scroller" className="best-stories__background" data-testid="PastWinners">
      <div
        className={clsx("best-stories__container", {
          mobile: isMobileDevice(),
        })}
      >
        <Navbar />
        <Grid>
          <Row center="xs">
            <AnimatedContent>
              <div className="title">{PAST_WINNERS}</div>
            </AnimatedContent>
          </Row>
          <Row center="xs">
            <Col lg={10} md={10} xs={12}>
              <AnimatedContent custom={1} delay={0.1}>
                <div className="description">
                  <p>{PAST_WINNERS_DESCRIPTION}</p>
                </div>
              </AnimatedContent>
            </Col>
          </Row>

          <AnimatedContent
            custom={2}
            delay={0.1}
            threshold={0.1}
            rootMargin={0}
          >
            <PastWinnersGrid firstNominationPeriod={nomination_year - 1} />
          </AnimatedContent>

        </Grid>
      </div>
      <Footer>
        <Support />
        <Copyright />
      </Footer>
    </div>
  );
};

export default PastWinners;
