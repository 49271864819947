import React, { useState, useEffect, useCallback, useLayoutEffect } from 'react';
import { gql } from "@apollo/client";
import { useNavigate } from "react-router-dom";
import { apolloClient } from '../../index';
import styles from "../best-stories/best-stories-winners/best-stories-winners.module.scss";
import { Col, Grid, Row } from "react-flexbox-grid";
import { PAST_WINNERS } from "../../constants/common";
import Button from "../../components/button";
import ThumbnailsList from "../best-stories/best-stories-content";

export async function loadWinnersThumb(nominationYear) {
    const maxNbr = 9999;
    const GET_STORIES_BY_PERIOD = gql`
    query checkRank($nominationYear:Long!){
        stories (
          filters:{
            and: [
              {nomination_year:{eq:$nominationYear}},
              {is_winner:{eq:true}}]
          }
          pagination:{limit:${maxNbr}} sort:"rank"){
          data{id attributes{
            nomination_year
            author_name
            nominated_primo_name
            cover_image{data{attributes{url}}}
            title
            text
            likes_number
            rank
            location {data {id attributes{name}}}
            tags {data {id attributes {name}}}
        }}
          meta{pagination{total}}
        }
      }`;
    try {
        const storiesResult = await apolloClient.query({
            query: GET_STORIES_BY_PERIOD,
            variables: { nominationYear },
        });
        if (storiesResult.error) {
            throw storiesResult.error
        };
        //else
        let stories = storiesResult?.data?.stories?.data;
        stories = stories?.length ? stories.map(x => {
            return {
                "id": x.id,
                "nominated_primo_name": x.attributes?.nominated_primo_name,//TODO
                "cover_image": x.attributes?.cover_image?.data?.attributes?.url,
                "title": x.attributes?.title,
                "text": x.attributes?.text,
                "location": x.attributes?.location?.data?.attributes?.name,
                "likes": x.attributes?.likes_number,
                "liked": false, //TODO
                "tags": x.attributes?.tags?.data?.map(t => t.attributes?.name),
            }
        }) : null;
        return stories;

    } catch (error) {
        console.error(error.message);
        return null;//Do not show wird error to user. May be handled in an other way!
    }
}

function ButtonShowPrevious({ period, winners }) {
    const [visibleStories, setVisibleStories] = useState(false);//initially not visible

    useLayoutEffect(() => {
        if (visibleStories) {
            const element = document.querySelector(
                `#past-winners_section-${period + 1}`
            );
            if (element) {
                const yOffset = -85;
                const y =
                    element.getBoundingClientRect().top + window.pageYOffset + yOffset;

                window.scrollTo({ top: y, behavior: 'smooth' });
            }
        }
    }, [visibleStories]);


    return <Grid>
        {!visibleStories
            ? <Row center="xs">
                <Col xs={8} md={5} lg={3}>
                    <Button classNames={styles.moreContentButton} onClick={() => setVisibleStories(true)}>
                        {PAST_WINNERS}
                    </Button>
                </Col>
            </Row>
            : <PeriodGrid period={period} winners={winners} />
        }
    </Grid>
}

function PeriodGrid({ period, winners }) {
    const [previousWinners, setPreviousPeriodWinners] = useState(null);
    const navigate = useNavigate();

    useEffect(() => {
        loadWinnersThumb(period - 1).then(wrs => {
            setPreviousPeriodWinners(wrs)
        });
    }, []);

    const handleClick = useCallback((item) => {
        if (item) {
            navigate(`/stories/${item.id}`);
        }
    }, []);

    return <section className={styles.periodSection}>
        <div
            className={styles.periodLabel}
            id={`past-winners_section-${period + 1}`}
        >
            {Number(period)}
        </div>
        <ThumbnailsList
            items={winners}
            onClick={handleClick}
            showTags />
        {previousWinners && <ButtonShowPrevious period={period - 1} winners={previousWinners} />}
    </section>
}

export default function PastWinnersGrid({ firstNominationPeriod }) {
    const [periodWinners, setPeriodWinner] = useState(null);

    useEffect(() => {
        loadWinnersThumb(firstNominationPeriod).then(winners => {
            setPeriodWinner(winners)
        });
    }, []);

    return periodWinners?.length && periodWinners?.length > 0
        ? <PeriodGrid period={firstNominationPeriod} winners={periodWinners} />
        : null;
}
