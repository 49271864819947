import React from "react";
import PropTypes from "prop-types";
import { Grid, Row, Col } from "react-flexbox-grid";
import clsx from "clsx";
// Components
import NavbarLogo from "./navbar-logo";
import NavbarLinks from "./navbar-links"


import { useTransparent } from "../../helpers/hooks";

// Styles
import "./navbar.scss";

const NavbarOriginal = ({ sitePhase, newsletterSent,downloadLink, likedStoriesLength, light }) => {
  const { transparent } = useTransparent();


  return (
    <nav
      className={clsx("bc-navbar", {
        transparent,
        light: transparent && light
      })}
    >
      <Grid>
        <Row className="bc-navbar-row">
          <Col xs={12}>
            <div className="bc-navbar__content-wrapper">
              <NavbarLogo />
              <NavbarLinks sitePhase={sitePhase} 
                newsletterSent={newsletterSent}
                downloadLink={downloadLink} 
                likedStoriesLength={likedStoriesLength}  />
            </div>
          </Col>
        </Row>
      </Grid>
    </nav>
  );
};

NavbarOriginal.defaultProps = {
  expandedVisible: false,
};

NavbarOriginal.propTypes = {
  expandedVisible: PropTypes.bool,
};

export default React.memo(NavbarOriginal);
