import React, { useState, useContext } from "react";
import clsx from "clsx";
import { Col, Grid, Row } from "react-flexbox-grid";
import { useNavigate, Link } from "react-router-dom";
import ReactPlayer from "react-player";

import Popup from "../../../../components/popup";
import Button from "../../../../components/button";
import { AppSettingsContext } from "../../../../App";

import {
  NOMINATE,
  ULTIMATE_FOUNDERS,
  VIEW,
  VOTE,
} from "../../../../constants/common";

import styles from "./third-section.module.scss";
import { AnimatedContent } from "../../../../components/animated-content";

export function getTitles({
  sitePhase,
  nominationText,
  compilingText,
  votesText,
  choosingText,
  winnersText,
  nomitationYear,
}) {
  const actualizeText = (txt) => {
    return !!(txt && nomitationYear)
      ? txt.replace("_YEAR_", nomitationYear)
      : txt;
  };
  switch (sitePhase) {
    case "Nomination":
      return {
        page: ULTIMATE_FOUNDERS,
        action: NOMINATE,
        url: "/share-your-story",
        text: actualizeText(nominationText),
      };
    case "Compiling":
      return {
        page: ULTIMATE_FOUNDERS,
        action: null,
        url: null,
        text: actualizeText(compilingText),
      };
    case "Voting":
      return {
        page: ULTIMATE_FOUNDERS,
        action: VOTE,
        url: "/vote-for-ultimate-founders",
        text: actualizeText(votesText),
      };
    case "Choosing":
      return {
        page: ULTIMATE_FOUNDERS,
        action: null,
        url: "/vote-for-ultimate-founders",
        text: actualizeText(choosingText),
      };
    default: //"Selection"
      return {
        page: ULTIMATE_FOUNDERS,
        action: VIEW,
        url: "/ultimate-founders-winners",
        text: actualizeText(winnersText),
      };
  }
}

export const ThirdSection = ({
  nominationText,
  compilingText,
  votesText,
  choosingText,
  winnersText,
  legacyRadioUrl,
}) => {
  const navigate = useNavigate();
  const [hovered, setHovered] = useState(false);
  const [showScrapBook, setShowScrapBook] = useState(false);
  const [showAudioRadio, setShowAudioRadio] = useState(false);

  const {
    site_phase: sitePhase,
    global_newsletter_sent: newsletterSent,
    nomination_year: nomitationYear,
  } = useContext(AppSettingsContext);

  const { page, action, url, text } = getTitles({
    sitePhase,
    nominationText,
    compilingText,
    votesText,
    choosingText,
    winnersText,
    nomitationYear,
  });

  return (
    <div
      id="global_activation_boxes"
      data-testid="ThirdSection"
      className={clsx(styles.root, {
        [styles.hovered]: hovered,
      })}
    >
      <div
        className={
          newsletterSent
            ? `${styles.wrapper} ${styles.isCompilingWrapper}`
            : styles.wrapper
        }
      >
        <Grid>
          {newsletterSent &&
            (sitePhase === "Voting" || sitePhase === "Choosing") && (
              <AnimatedContent rootMargin={10} threshold={0.05}>
                <div className={styles.compilingPhaseImages}>
                  <a
                    title="Created by Primos for Primos, our global digital scrapbook will serve as a collection of memories capturing major moments, both collective and individual, shaping our legacy today and into the future.
              Share your moments! Upload up to 5 images and a short description that captures your #BacardiLife by Thursday, January 18."
                    className={styles.compilingImgWrap}
                    href="https://forms.office.com/r/mRHYkNGRYe"
                    target="_blank"
                    rel="noreferrer"
                  >
                    <div
                      className={`${styles.compilingPhaseImg} ${styles.compilingPhaseImgScrapbook}`}
                    />
                  </a>

                  <a
                    title="Featuring soundbites and song requests, Legacy Radio will use music to connect us. Send in your “Legacy Track” by Thursday, January 18 for a chance to be included in this 2-hour cultural music mix – tell us why the track is so special, how it makes you feel – all while inspiring Primos around the world."
                    className={styles.compilingImgWrap}
                    href="https://forms.office.com/r/WvTqzVpu05"
                    target="_blank"
                    rel="noreferrer"
                  >
                    <div
                      className={`${styles.compilingPhaseImg} ${styles.compilingPhaseImgRadio}`}
                    />
                  </a>

                  <a
                    title="We invite you to make it personal. Share your celebratory photo and let us transform you into the hero of your very own cocktail adventure. Picture this: you are splashing into a Gin & Tonic, striking a superhero pose by a Mojito, or relaxing poolside with a Margarita. Let our designers work their magic. Run, don’t walk - the first 162 Primos to submit their photo by Thursday, January 18 will receive a bespoke poster to print or use digitally."
                    className={styles.compilingImgWrap}
                    href="https://forms.office.com/r/K84s38Dhkt"
                    target="_blank"
                    rel="noreferrer"
                  >
                    <div
                      className={`${styles.compilingPhaseImg} ${styles.compilingPhaseImgPosters}`}
                    />
                  </a>

                  {sitePhase === "Voting" && (
                    <a
                      title="Recognize Primos making a big difference at Bacardi by casting your votes for the Ultimate Founders Competition. The judges have narrowed down the nominations and selected 17 remarkable Primos for the shortlist. Read their stories "
                      className={styles.compilingImgWrap}
                      href="https://foundersday.bacardi.com/vote-for-ultimate-founders"
                    >
                      <div
                        className={`${styles.compilingPhaseImg} ${styles.compilingPhaseImgUFC}`}
                      />
                    </a>
                  )}
                  {sitePhase === "Choosing" && (
                    <div className={styles.compilingImgWrap}>
                      <div
                        className={`${styles.compilingPhaseImg} ${styles.compilingPhaseImgUFCRevealedSoon}`}
                      />
                    </div>
                  )}

                  <div
                    title="Spot the palm tree! We will strategically “plant” digital palm trees across our internal platforms. A riddle will be released per day starting January 29 through February 1, and it’s up to you to find the location and submit your findings in the correct order to be up for winning a prize. The winners will be randomly selected for those that provide the correct sequence. Happy palm tree spotting!"
                    className={styles.compilingImgWrap}
                  >
                    <div
                      className={`${styles.compilingPhaseImg} ${styles.compilingPhaseImgQuest}`}
                    />
                  </div>

                  <div
                    title="Creative assets will be available for you to download on Founder’s Week, kicking off January 29 through February 2. Here you’ll find everything from branded PowerPoint templates, Microsoft Teams backgrounds, and more!"
                    className={styles.compilingImgWrap}
                  >
                    <div
                      className={`${styles.compilingPhaseImg} ${styles.compilingPhaseImgAssets}`}
                    />
                  </div>
                </div>
              </AnimatedContent>
            )}

          {newsletterSent && sitePhase === "Selection" && (
            <AnimatedContent rootMargin={10} threshold={0.05}>
              <div className={styles.compilingPhaseImages}>
                <div
                  onClick={() => setShowScrapBook(true)}
                  style={{ cursor: "pointer" }}
                  // onClick={() => {}}
                  className={styles.compilingImgWrap}
                >
                  <div
                    className={`${styles.compilingPhaseImg} ${styles.selectionPhaseImgScrapbook}`}
                  />
                </div>

                <div
                  className={styles.compilingImgWrap}
                  onClick={() => setShowAudioRadio(true)}
                  style={{ cursor: "pointer" }}
                  // onClick={() => {}}
                >
                  <div
                    className={`${styles.compilingPhaseImg} ${styles.selectionPhaseImgRadio}`}
                  />
                </div>
              </div>
              <div className={styles.compilingPhaseImages}>
                <a
                  className={styles.compilingImgWrap}
                  href="https://bacardi.sharepoint.com/sites/FoundersDay2024ToolKits"
                  target="_blank"
                  rel="noreferrer"
                >
                  <div
                    className={`${styles.compilingPhaseImg} ${styles.selectionPhaseDownloadAssets}`}
                  />
                </a>

                <Link
                  className={styles.compilingImgWrap}
                  to="/ultimate-founders-winners"
                >
                  <div
                    className={`${styles.compilingPhaseImg} ${styles.selectionPhaseImgUFC}`}
                  />
                </Link>
                {/* <div className={styles.compilingImgWrap}>
                  <div
                    className={`${styles.compilingPhaseImg} ${styles.selectionPhaseImgQuest}`}
                  />
                </div> */}
              </div>
            </AnimatedContent>
          )}

          {!newsletterSent && (
            <>
              <Row>
                <Col xs={12}>
                  <AnimatedContent className={clsx(styles.title)}>
                    {page}
                  </AnimatedContent>
                </Col>
              </Row>
              <Row className={styles.textWrapper}>
                <Col xs={12} mdOffset={1} md={10}>
                  <AnimatedContent>
                    <p>{text}</p>
                  </AnimatedContent>
                </Col>
              </Row>
            </>
          )}

          {sitePhase === "Nomination" && (
            <AnimatedContent>
              <div className={styles.threeImages}>
                <div className={styles.tagImgWrap}>
                  <div className={`${styles.tag1Img} ${styles.tagImg}`} />
                </div>
                <div className={styles.tagImgWrap}>
                  <div className={`${styles.tag2Img} ${styles.tagImg}`} />
                </div>
                <div className={styles.tagImgWrap}>
                  <div className={`${styles.tag3Img} ${styles.tagImg}`} />
                </div>
              </div>
            </AnimatedContent>
          )}

          {!newsletterSent && action && (
            <AnimatedContent className={styles.buttonWrapper}>
              <Button
                classNames={styles.hoveredButton}
                onClick={() => {
                  navigate(url);
                }}
                auto={false}
                onMouseEnter={() => setHovered(true)}
                onMouseLeave={() => setHovered(false)}
              >
                {action}
              </Button>
            </AnimatedContent>
          )}
        </Grid>
      </div>

      <Popup
        buttons={[]}
        size="fullscreen"
        isShown={showScrapBook}
        handleClose={() => setShowScrapBook(false)}
        backgroundColor="#303030"
      >
        <div className={styles.popupContainer}>
          <iframe
            title="digital-scrapbook"
            src="https://indd.adobe.com/embed/8931102f-6c18-40b8-8db3-9dcefd49a038?startpage=1&allowFullscreen=true"
            frameBorder="0"
            allowFullScreen=""
          ></iframe>
        </div>
      </Popup>

      <Popup
        buttons={[]}
        size="radio"
        isShown={showAudioRadio}
        handleClose={() => setShowAudioRadio(false)}
        backgroundColor="#f1f3f4"
      >
        <div>
          <div className={styles.legacyRadioOn}></div>
          <ReactPlayer
            // url="/assets/audios/legacy-radio.mp3"
            url={legacyRadioUrl}
            controls
            playing
            width="100%"
            height="55px"
            // height="100%"
          />
        </div>
      </Popup>
    </div>
  );
};
