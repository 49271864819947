import React, { useState } from "react";
import PropTypes from "prop-types";
import clsx from "clsx";
import { Col, Grid, Row } from "react-flexbox-grid";
import { Icons } from "../../../../components/icon";
import styles from "./first-section.module.scss";
import sharedStyles from "../shared.module.scss";
import { AnimatedContent } from "../../../../components/animated-content";

export const FirstSection = ({ text }) => {
  const [hovered, setHovered] = useState(false);

  return (
    <div
      className={clsx(styles.root, {
        [styles.hovered]: hovered,
      })}
    >
      <div className={styles.background} />
      <div className={`${styles.containerW} ${sharedStyles.globalWidth}`}>
      <div className={styles.container}>
      <div className={styles.headerLockupW}> <div className={styles.headerLockup} /></div>
      <div className={styles.headerImageW}> <div className={styles.headerImage} /></div>
      </div>
</div>
      {/* <Grid className={styles.container}>
        <Row className={styles.row}>
          <Col xs={12}>
            <div
              className={styles.titleWrapper}
              onMouseEnter={() => setHovered(true)}
              onMouseLeave={() => setHovered(false)}
            >
              <AnimatedContent
                translate={0}
                custom={1}
                delay={0.2}
                className={styles.title}
              >
                Make
              </AnimatedContent>
              <AnimatedContent
                translate={0}
                custom={2}
                delay={0.2}
                className={styles.title}
              >
                Moments
              </AnimatedContent>
              <AnimatedContent
                translate={0}
                custom={3}
                delay={0.2}
                className={styles.title}
              >
                Matter
              </AnimatedContent>
            </div>
          </Col>
        </Row>
        <Row className={clsx(styles.row, styles.textWrapper)}>
          <Col xs={12} sm={12} md={11} lg={7}>
            <AnimatedContent delay={0.2} custom={4}>
              <p>{text}</p>
            </AnimatedContent>
          </Col>
          <Col xs={12} sm={12} md={1} lg={1} lgOffset={4}>
            <div className={styles.logo}>{Icons.BAT_LOGO}</div>
          </Col>
        </Row>
      </Grid> */}
    </div>
  );
};

FirstSection.defaultProps = {
  text: `Since 1862, Bacardi’s created a more authentically connected and
              joyful world. But how do we make that happen today? We realized it
              wasn’t exactly by “celebrating moments that matter, one drink at a
              time.” Not all moments are for celebrating, and we don’t always
              have a drink in our hand. That is why Bacardi is always in pursuit
              of a world full of authenticity, joy and connection.`,
};

FirstSection.propTypes = {
  text: PropTypes.string,
};
