import React from 'react';
import PropTypes from 'prop-types';
import { useNavigate } from "react-router-dom";
import { Grid, Row, Col } from 'react-flexbox-grid';
import clsx from 'clsx';

// Components
import { Icons } from '../../icon';

// Styles
import '../navbar-mobile.scss';

const WizardNavbarMobile = ({ showClose, step }) => {
  const navigate = useNavigate();

  return (
    <nav
      id='bc-navbar-mobile'
      className={clsx('bc-navbar-mobile minimized wizard')}
    >
      <Grid fluid>
        <Row className='bc-navbar-row'>
          <Col xs={7}>
            <div
              className={clsx('bc-logo__wrapper clickable')}
              onClick={() => navigate('/homepage')}
            >
              <div className='icon'>{Icons.MAKE_MOMENTS_MATTER}</div>
            </div>
          </Col>
          {showClose && step !== 3 && (
            <Col xs={3} xsOffset={2}>
              <div
                className='bc-icon-button'
                onClick={() => navigate('/homepage')}
              >
                <span className='label'>CLOSE</span>
                <span className='icon'>{Icons.CLOSE}</span>
              </div>
            </Col>
          )}
        </Row>
      </Grid>

    </nav>
  );
};

// WizardNavbarMobile.defaultProps = {
//   showClose: false
// };

// WizardNavbarMobile.propTypes = {
//   showClose: PropTypes.bool,
//   steps: PropTypes.arrayOf(PropTypes.shape({})).isRequired
// };

export default WizardNavbarMobile;
